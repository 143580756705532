import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Greenearth from '../assets/Greenearth.png';


function Slidercards() {
  return (
    <div className="bg-black text-white py-16 mt-32">
      <div className="max-w-7xl mx-auto px-6">
        <h1 className="text-4xl md:text-5xl font-bold mb-8 text-center">Discover AIAD's Vision</h1>
        <p className="text-center text-gray-300 mb-2">
          Dive into AIAD's innovative solutions that are shaping the future of digital advertising.
        </p>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          breakpoints={{
            768: {
              slidesPerView: 3,
            },
          }}
          loop={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
            el: '.swiper-pagination',
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
        >
          {/* Slides */}
          {[
            {
              title: 'AIAD Green Pass',
              description:
                'Unlock exclusive utilities within the AIAD ecosystem. Limited to 111 Green Passes, providing premium tools and benefits.',
              buttonText: 'Whitelist',
            },
            {
              title: 'AI-Powered Innovation',
              description:
                'Revolutionizing advertising with AI-driven tools on the Solana blockchain. Transforming the advertising world.',
              buttonText: 'Get started',
            },
            {
              title: 'Transparency',
              description:
                'Bringing transparency and efficiency to advertising, fostering trust and accessibility for everyone involved.',
              buttonText: 'Register now',
            },
            {
              title: 'Advertisers and Publishers',
              description:
                'Empowering advertisers and publishers with direct engagement and unparalleled efficiency in the advertising ecosystem.',
              buttonText: 'Get Started',
            },
          ].map((slide, index) => (
            <SwiperSlide key={index}>
              <div className="flex justify-center items-center py-10">
                <div
                  className="md:max-w-sm h-[370px] md:h-[350px] p-7 rounded-3xl shadow-lg bg-cover bg-center relative"
                  style={{ backgroundImage: `url(${Greenearth})` }}
                >
                  <div className="absolute inset-0 bg-black bg-opacity-50 rounded-3xl"></div>
                  <div className="relative z-10">
                    <h2 className="mb-5 text-2xl font-bold text-white">{slide.title}</h2>
                    <p className="text-lg text-gray-300 mb-6">{slide.description}</p>
                    <a href="https://docs.aiad.info" target="_blank" rel='noreferrer noopener'>
                    <button className="px-6 py-3 text-sm font-semibold bg-gradient-to-r from-teal-400 to-blue-500 text-black rounded-full hover:bg-gradient-to-r hover:from-blue-500 hover:to-teal-400 transition-colors duration-300">
                      {slide.buttonText}
                    </button>
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-pagination mt-6"></div>
      </div>
    </div>
  );
}

export default Slidercards;
