import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Sliderkingdesktop.css"; // Custom CSS for Swiper pagination
import Aiadglimpseimage from "../assets/Aiadglimpseimage.png";
import Logo from "../assets/logo512.png";
import Aiadbannerimg from "../assets/AIAD-Banner.svg";
import Chartwebp from "../assets/Chart.webp";
import { Link } from "react-router-dom";

function Sliderkingdesktop() {
  return (
    <div className="block screen w-full justify-center items-center border-none md:px-56 py-1 md:py-14 px-5 bg-gradient-to-b from-[#000000] to-[#000000] via-[#001013]">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          el: ".swiper-pagination",
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
      >
        {/* 1st Slide */}
        <SwiperSlide>
          <div className="flex flex-col md:flex-row items-center justify-center bg-gradient-to-br from-[#210229] to-[#023324] text-white md:p-1 p-4 md:py-5 md:px-10 rounded-lg shadow-lg md:h-[400px] h-screen">
            <div className="flex-1 flex justify-center items-center">
              <img
                className="rounded-lg shadow-lg hover:scale-105 transition-transform duration-300"
                src={Aiadglimpseimage}
                alt="$AIAD"
              />
            </div>
            <div className="flex-1 flex flex-col justify-center items-center p-6 md:p-10">
              <span className="bg-gradient-to-r from-teal-400 to-blue-500 text-black text-sm font-semibold px-4 py-2 rounded-full mb-4">
                BREAKPOINT 2025
              </span>
              <h2 className="text-2xl md:text-4xl font-bold leading-tight mb-4">
                AIAD Green Pass
              </h2>
              <p className="text-gray-300 mb-6">
                Your gateway to unlocking exclusive utilities within the AIAD
                ecosystem. As one of only 111 limited Passes, holders will have
                access to unique benefits, advanced tools, and premium features
                across the AIAD platform.
              </p>
              <a href="https://docs.aiad.info/GreenPass.html" target="_blank" rel='noreferrer noopener'>
              <button className="px-6 py-3 text-sm font-semibold border border-white rounded-full hover:bg-white hover:text-gray-900 transition-colors duration-300">
                Learn more...
              </button>
              </a>
            </div>
          </div>
        </SwiperSlide>

        {/* 2nd Slide */}
        <SwiperSlide>
        <div className="flex flex-col md:flex-row items-center justify-center bg-gradient-to-br from-[#210229] to-[#023324] text-white md:p-1 p-4 md:py-5 md:px-10 rounded-lg shadow-lg md:h-[400px] h-screen">
            <div className="flex-1 flex justify-center items-center">
              <div>
                <div className="flex justify-center items-center w-[190px] h-[190px] rounded-[190px] bg-gradient-to-b from-[#fb04f7] to-[#3c13f2]">
                  <div className="flex justify-center items-center w-[180px] h-[180px] rounded-[180px] bg-[#000000]">
                    <div className="flex justify-center items-center w-[170px] h-[170px] rounded-[170px] bg-gradient-to-r from-[#9404fb] to-[#692ff1]">
                      <div className="flex justify-center items-center w-[160px] h-[160px] rounded-[160px] bg-black">
                        <div className="flex animate-pulse absolute justify-center items-center w-[150px] h-[150px] rounded-[370px] bg-gradient-to-b from-[#9d03fd] to-[#693bc5]">
                          <div className="flex justify-center items-center w-[140px] h-[140px] rounded-[320px] bg-black">
                            <div>
                              <img src={Logo} alt="AIAD" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col justify-center items-center p-6 md:p-10">
              <span className="bg-gradient-to-r from-teal-400 to-blue-500 text-black text-sm font-semibold px-4 py-2 rounded-full mb-4">
                BREAKPOINT 2025
              </span>
              <h2 className="text-2xl md:text-4xl font-bold leading-tight mb-4">
                Advertisers and Publishers
              </h2>
              <p className="text-gray-300 mb-6">
                A new level of efficiency, cost-effectiveness, and direct
                engagement between advertisers and publishers. This innovation
                is set to redefine the advertising landscape.
              </p>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLScnbB2xix5Feyd0Uit5cfC2EqzfhxuxI2HemUrOu_MD6yGujQ/viewform" target="_blank" rel='noreferrer noopener'>
              <button className="px-6 py-3 text-sm font-semibold border border-white rounded-full hover:bg-white hover:text-gray-900 transition-colors duration-300">
                GET TICKETS
              </button>
              </a>
            </div>
          </div>
        </SwiperSlide>

        {/* 3rd Slide */}
        <SwiperSlide>
        <div className="flex flex-col md:flex-row items-center justify-center bg-gradient-to-br from-[#210229] to-[#023324] text-white md:p-1 p-4 md:py-5 md:px-10 rounded-lg shadow-lg md:h-[400px] h-screen">
            <div className="flex-1 flex justify-center items-center md:p-20">
              <img
                src={Chartwebp}
                className="rounded-lg shadow-lg hover:scale-105 p-1 transition-transform duration-300 md:p-14"
                alt="AIAD Tokenomics"
              />
            </div>
            <div className="flex-1 flex flex-col justify-center items-center p-6 md:p-10">
              <span className="bg-gradient-to-r from-teal-400 to-blue-500 text-black text-sm font-semibold px-4 py-2 rounded-full mb-4">
                BREAKPOINT 2025
              </span>
              <h2 className="text-2xl md:text-4xl font-bold leading-tight mb-4">
                Tokenomics of $AIAD
              </h2>
              <p className="text-gray-300 mb-6">
                AIAD's tokenomics strategy is designed to ensure the long-term
                viability and growth of the platform.
              </p>
              <a href="https://docs.aiad.info/aiad-token/tokendistribution.html" target="_blank" rel='noreferrer noopener'>
              <button className="px-6 py-3 text-sm font-semibold border border-white rounded-full hover:bg-white hover:text-gray-900 transition-colors duration-300">
                Learn more...
              </button>
              </a>
            </div>
          </div>
        </SwiperSlide>

        {/* 4th Slide */}
        {[4].map((slide) => (
          <SwiperSlide key={slide}>
            <div className="flex flex-col md:flex-row items-center justify-center bg-gradient-to-br from-[#210229] to-[#023324] text-white md:p-1 p-4 md:py-5 md:px-10 rounded-lg shadow-lg md:h-[400px] h-screen">
              <div className="flex-1 flex justify-center items-center">
                <img
                  className="rounded-lg shadow-lg hover:scale-105 p-1 transition-transform duration-300"
                  src={Aiadbannerimg}
                  alt={`Slide ${slide}`}
                />
              </div>
              <div className="flex-1 flex flex-col justify-center items-center p-6 md:p-10">
                <span className="bg-gradient-to-r from-teal-400 to-blue-500 text-black text-sm font-semibold px-4 py-2 rounded-full mb-4">
                  BREAKPOINT 2025
                </span>
                <h2 className="text-2xl md:text-4xl font-bold leading-tight mb-4">
                  Core Metrics
                </h2>
                <p className="text-gray-300 mb-6">
                  A tamper-proof and transparent advertisement ecosystem that
                  allows advertisers and affiliates to track the performance of
                  their ads in real-time, ensuring accurate data and preventing
                  click fraud. Metrics are recorded on the Solana blockchain,
                  making them transparent, secure, and verifiable.
                </p>
                <Link to="https://docs.aiad.info/DeveloperResources/APIDocumentation.html">
                  <button className="px-6 py-3 text-sm font-semibold border border-white rounded-full hover:bg-white hover:text-gray-900 transition-colors duration-300">
                    Learn more...
                  </button>
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Sliderkingdesktop;
