import React from 'react';
import Aiadbannerimg from '../assets/AIADVCW.png';

function Aiadbanner() {
  return (
    
    <div className='flex justify-center py-[20px] bg-gradient-to-b from-[#00000000] via-[#002b2a] to-[#00000000] '>
<div>
    
    <img src={Aiadbannerimg} alt='AIAD Ads Banner' className='flex transition-transform duration-200 ease-in-out transform hover:-translate-y-1 hover:scale-102 animate-none'></img>
    
</div>

    </div>
  )
}

export default Aiadbanner