import React from 'react';

function Card2() {
  return (
    <div className="screen w-full bg-black justify-center items-center border-none p-[25px]">
      <div className="text-white mb-10">
        <h1 className="text-4xl font-bold text-center">Made for Mass Adoption</h1>
        <p className="text-center text-sm text-gray-400 mt-2">LIVE DATA</p>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
        {/* Card 1 */}
        <div className="bg-gradient-to-br from-gray-800 via-black to-gray-900 p-6 rounded-3xl shadow-lg">
          <h3 className="text-xl font-bold text-white mb-2">Fast Transactions</h3>
          <p className="text-sm text-gray-300 mb-4">
            Solana processes transactions with minimal delay, ensuring seamless interactions.
          </p>
          <h2 className="text-3xl font-bold text-cyan-400">65,000+</h2>
          <p className="text-sm text-gray-400">TRANSACTIONS PER SECOND</p>
        </div>

        {/* Card 2 */}
        <div className="bg-gradient-to-br from-gray-800 via-black to-gray-900 p-6 rounded-3xl shadow-lg">
          <h3 className="text-xl font-bold text-white mb-2">Decentralized Network</h3>
          <p className="text-sm text-gray-300 mb-4">
            Solana ensures security and censorship resistance through its decentralized architecture.
          </p>
          <h2 className="text-3xl font-bold text-orange-400">1,961</h2>
          <p className="text-sm text-gray-400">ACTIVE VALIDATOR NODES</p>
          <a
            href="https://www.gemini.com/cryptopedia/solana-blockchain" target='_blank' rel='noreferrer noopener'
            className="text-sm text-cyan-400 underline hover:text-cyan-500 mt-2 inline-block"
          >
            LEARN MORE →
          </a>
        </div>

        {/* Card 3 */}
        <div className="bg-gradient-to-br from-gray-800 via-black to-gray-900 p-6 rounded-3xl shadow-lg">
          <h3 className="text-xl font-bold text-white mb-2">Scalable Infrastructure</h3>
          <p className="text-sm text-gray-300 mb-4">
            Designed to handle thousands of transactions per second with low fees for users and developers.
          </p>
          <h2 className="text-3xl font-bold text-green-400">352 Billion+</h2>
          <p className="text-sm text-gray-400">TOTAL TRANSACTIONS</p>
        </div>

        {/* Card 4 */}
        <div className="bg-gradient-to-br from-gray-800 via-black to-gray-900 p-6 rounded-3xl shadow-lg">
          <h3 className="text-xl font-bold text-white mb-2">Energy Efficient</h3>
          <p className="text-sm text-gray-300 mb-4">
            Solana minimizes environmental impact while maintaining high performance for its users.
          </p>
          <h2 className="text-3xl font-bold text-green-400">0%</h2>
          <p className="text-sm text-gray-400">NET CARBON IMPACT</p>
          <a
            href="https://solana.com/news/solana-foundation-carbon-neutral-2021" target='_blank' rel='noreferrer noopener'
            className="text-sm text-cyan-400 underline hover:text-cyan-500 mt-2 inline-block"
          >
            LEARN MORE →
          </a>
        </div>
      </div>
    </div>
  );
}

export default Card2;
