import React from 'react';
import Logo from '../assets/logo512.png'
import {
  FaArrowUp,
  FaDribbbleSquare,
  FaGithubSquare,
  FaTelegram,
  FaTwitterSquare,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div className="flex w-full justify-center items-center px-[25px]">
    <div id="FooterA" className='md:max-w-[1400px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300'>
        <div>
            <h1 className='w-full text-3xl font-bold text-[#00df9a]'>$AIAD</h1>
            <p className='flex justify-between md:w-[75%] my-6'> A decentralized solution for the digital advertising industry, addressing the challenges of transparency, security, efficiency, and user privacy.</p>
        <div className='flex justify-between md:w-[75%] my-6'>
    <a href="https://aiad.info" target="_blank" rel="noopener noreferrer" ><FaDribbbleSquare  size={30}/></a>
    <a href="https://github.com/AIADOfficial" target="_blank" rel="noopener noreferrer" ><FaGithubSquare size={30}/></a>
    <a href="https://twitter.com/AIADOfficial" target="_blank" rel="noopener noreferrer" ><FaTwitterSquare size={30}/></a>
    <a href="https://t.me/AIADOfficial" target="_blank" rel="noopener noreferrer" ><FaTelegram size={30}/></a>
        </div>
        </div>

<div className='lg:col-span-2 flex justify-between mt-6'>
<div>
    <h6 className='font-medium text-gray-400 '>Company</h6>
    <ul>
        <a href="https://coinmarketcap.com/" target="_blank" rel='noreferrer noopener'>
        <li className='py-2 text-sm'>CoinMarketCap</li>
        </a>
        <a href="https://solscan.io/" target="_blank" rel='noreferrer noopener'>
        <li className='py-2 text-sm'>Solscan</li>
        </a>
        <a href="https://docs.aiad.info/DeveloperResources/SmartContractSpecifications.html" target="_blank" rel='noreferrer noopener'>
        <li className='py-2 text-sm'>Smart Contract</li>
        </a>
        <a href="https://docs.aiad.info/DeveloperResources/APIDocumentation.html" target="_blank" rel='noreferrer noopener'>
        <li className='py-2 text-sm'>API Documentation</li>
        </a>
    </ul>
</div><div>
    <h6 className='font-medium text-gray-400 '>Communication</h6>
    <ul>
    <a href="https://docs.google.com/forms/d/e/1FAIpQLScnbB2xix5Feyd0Uit5cfC2EqzfhxuxI2HemUrOu_MD6yGujQ/viewform" target="_blank" rel='noreferrer noopener'>
    <li className='py-2 text-sm'>Contact</li>
    </a>

        <a href="https://docs.google.com/forms/d/e/1FAIpQLScnbB2xix5Feyd0Uit5cfC2EqzfhxuxI2HemUrOu_MD6yGujQ/viewform" target="_blank" rel='noreferrer noopener'>
        <li className='py-2 text-sm'>Investors</li>
        </a>
        <a href="https://docs.aiad.info/DeveloperResources/APIDocumentation.html" target="_blank" rel='noreferrer noopener'>
        <li className='py-2 text-sm'>Developers</li>
        </a>
        <a href="https://docs.aiad.info/DeveloperResources/APIDocumentation.html" target="_blank" rel='noreferrer noopener'>
        <li className='py-2 text-sm'>Press</li>
        </a>
        
    </ul>
</div><div>
    
    <ul>
        <li className='py-2 text-sm'></li>
        <li className='py-2 text-sm'></li>
        <li className='py-2 text-sm'></li>
        <li className='py-2 text-sm'></li>
        
    </ul>
</div>
</div>
<div className='lg:col-span-3 flex justify-between'>
<div>
       <img className='flex w-[150px] h-[150px]' src={Logo} alt="$AIAD Token" />
    </div><div>
        <ul>
            <li className='py-2 text-sm'></li>
            <li className='py-2 text-sm'></li>
            <li className='py-2 text-sm'></li>
            <li className='py-2 text-sm'></li>
        </ul>
    </div>
    <div className='mt-5'>
    <a href="#Navbar">    
        <FaArrowUp size={80}/>
            <p className='flex justify-center mt-3'>
                PAGE UP
            </p></a>
    
    </div>

</div>
</div>
    </div>
  )
}

export default Footer;
