import React, { useEffect, useState } from "react";
import Aiadbannerimg from '../assets/AIADVCW.png';

const FundingCard = () => {
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    let start = 0;
    const end = 1.2; // Target number
    const duration = 3000; // Duration in milliseconds
    const stepTime = 20; // Update frequency in milliseconds
    const increment = (end - start) / (duration / stepTime);

    const interval = setInterval(() => {
      start += increment;
      if (start >= end) {
        start = end;
        clearInterval(interval);
      }
      setAmount(start.toFixed(1));
    }, stepTime);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="h-screen flex items-center justify-center bg-gradient-to-b from-[#000000] to-[#000000] via-[#00242a]">
      {/* Semi-Transparent Gradient Background */}
      <div className="absolute inset-0 bg-gradient-to-r from-teal-400 via-purple-500 to-blue-600 opacity-20 blur-lg -z-10"></div>

      {/* Card Content */}
      <div className="bg-none border border-teal-500 rounded-xl shadow-lg text-center p-8 max-w-xl w-full mx-2" >
        
        <h1 className="text-7xl font-bold text-[#96e5e6] mt-2">${amount} Million</h1>
        
        <h2 className="text-3xl font-bold text-[#ffffff]">
          Raised
        </h2>
        
        <p className="text-gray-300 mt-4">
          Thanks to our supporters and investors, we've raised $1.2M to build
          the future of AI-powered advertising. Join us in shaping the next big
          thing!
        </p>
        <a href="https://docs.aiad.info/CommunityandSupport.html" target="_blank" rel='noreferrer noopener'>
        <button className="mt-6 px-6 py-3 bg-gradient-to-r from-teal-400 to-blue-500 hover:from-blue-500 hover:to-teal-400 text-white font-semibold rounded-full shadow-lg transition-transform transform hover:scale-105">
          Learn More
        </button>
        </a>
        <div>
      
        <img src={Aiadbannerimg} alt='AIAD Ads Banner' className='flex transition-transform duration-200 ease-in-out transform hover:-translate-y-1 hover:scale-102 animate-none'></img>
      
    </div>
      </div>
      <div>
        
      </div>
    </div>
  );
};

export default FundingCard;
