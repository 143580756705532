import React from 'react';
import Purplebg from '../assets/Purplebg.png';

function CaseStudies() {
  return (
    <div className="bg-black text-white py-16">
      <div className="max-w-7xl mx-auto px-6">
        <h1 className="text-4xl md:text-5xl font-bold mb-8">Designed for real-world impact.</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {/* First Card */}
          <div
            className="relative bg-cover bg-center rounded-lg p-6 h-[300px]"
            style={{ backgroundImage: `url(${Purplebg})` }}
          >
            <span className="absolute top-4 left-4 text-sm font-semibold text-purple-400">
              CASE STUDY
            </span>
            <h2 className="text-2xl font-bold mt-8">AIAD Metrics</h2>
            <p className="text-gray-300 mt-2">
              Leveraging blockchain transparency, AIAD enables real-time ad performance tracking.
            </p>
            <a href="https://docs.aiad.info/DeveloperResources/SmartContractSpecifications.html" target="_blank" rel='noreferrer noopener'>
            <button className="absolute bottom-4 right-4 text-white border border-white p-2 rounded-full">
              Technology →
            </button>
            </a>
          </div>

          {/* Second Card */}
          <div
            className="relative bg-cover bg-center rounded-lg p-6 h-[300px]"
            style={{ backgroundImage: `url(${Purplebg})` }}
          >
            <span className="absolute top-4 left-4 text-sm font-semibold text-purple-400">
              CASE STUDY
            </span>
            <h2 className="text-2xl font-bold mt-8">Ad Campaigns</h2>
            <p className="text-gray-300 mt-2">
              AI-powered targeting improved ad engagement by 45% in a pilot campaign.
            </p>
            <a href="https://docs.aiad.info/CoreFeatures/AdCampaignManagement.html" target="_blank" rel='noreferrer noopener'>
            <button className="absolute bottom-4 right-4 text-white border border-white p-2 rounded-full">
             Dev space →
            </button>
            </a>
          </div>

          {/* Third Card */}
          <div
            className="relative bg-cover bg-center rounded-lg p-6 h-[300px]"
            style={{ backgroundImage: `url(${Purplebg})` }}
          >
            <span className="absolute top-4 left-4 text-sm font-semibold text-purple-400">
              CASE STUDY
            </span>
            <h2 className="text-2xl font-bold mt-8">Blockchain Ads</h2>
            <p className="text-gray-300 mt-2">
              AIAD reduced click fraud by 80% using blockchain transparency in ad tracking.
            </p>
            <a href="https://docs.aiad.info/CoreFeatures/FraudDetection.html" target="_blank" rel='noreferrer noopener'>
            <button className="absolute bottom-4 right-4 text-white border border-white p-2 rounded-full">
              Sttudy →
            </button>
            </a>
          </div>

          {/* Fourth Card */}
          <div
            className="relative bg-cover bg-center rounded-lg p-6 h-[300px]"
            style={{ backgroundImage: `url(${Purplebg})` }}
          >
            <span className="absolute top-4 left-4 text-sm font-semibold text-purple-400">
              VIDEO
            </span>
            <h2 className="text-2xl font-bold mt-8">User Engagement</h2>
            <p className="text-gray-300 mt-2">
              Innovative AI targeting increased ad CTR to 3.2%, outperforming industry standards.
            </p>
            <a href="https://docs.aiad.info/CommunityandSupport.html" target="_blank" rel='noreferrer noopener'>
            <button className="absolute bottom-4 right-4 text-white border border-white p-2 rounded-full">
              How →
            </button>
            </a>
          </div>
        </div>
        <div className="flex justify-end mt-8">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScnbB2xix5Feyd0Uit5cfC2EqzfhxuxI2HemUrOu_MD6yGujQ/viewform" target="_blank" rel='noreferrer noopener'>       <button className="text-white border border-white px-6 py-2 rounded-lg hover:bg-white hover:text-black transition">
            BECOME A PARTNER →
          </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default CaseStudies;
