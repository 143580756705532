import React from 'react';

function CallToAction() {
  return (
    <div className="relative flex items-center justify-center min-h-[400px] bg-black text-white overflow-hidden md:py-24">
      {/* Background Gradient */}
      <div className="absolute inset-0 bg-gradient-to-br from-[#030000] via-[#000e39] to-green-700 opacity-30 pointer-events-none"></div>

      {/* Content */}
      <div className="relative z-10 max-w-4xl text-center px-6 py-12 md:px-12 md:py-16 bg-[#453265] bg-opacity-20 rounded-3xl shadow-lg">
        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-6 leading-tight">
          It's time to join the thousands of creators, artists, and developers using AIAD.
        </h2>
        <p className="text-lg md:text-xl text-gray-300 mb-8">
          Unlock your potential and explore innovative solutions for a smarter, more transparent future in advertising.
        </p>
        <a href="https://docs.aiad.info/DeveloperResources/DeveloperToolsandSDKs.html" target="_blank" rel='noreferrer noopener'>
        <button className="px-8 py-3 text-lg font-semibold bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded-full shadow-md hover:from-blue-500 hover:to-purple-500 transition-all duration-300">
          Start Building
        </button>
        </a>
      </div>

      {/* Subtle Lines */}
      <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
        <svg className="absolute inset-0 w-full h-full" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1440 320">
          <path
            fillOpacity="0.2"
            d="M0,64L48,85.3C96,107,192,149,288,160C384,171,480,149,576,144C672,139,768,149,864,170.7C960,192,1056,224,1152,229.3C1248,235,1344,213,1392,202.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>
    </div>
  );
}

export default CallToAction;
