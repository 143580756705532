import React from 'react'
import '../assets/Logomobile.css';
import Logo from '../assets/logo512.png';

const Aiadlogomobile = () => {
  return (



<div className="md:hidden flex main-section-background-mobile w-max-1/4 justify-center items-center py-14">


        <div className='flex justify-center pt-[0px]'>
        
       </div>
        <div>
        <div className='flex justify-center items-center w-[150px] h-[150px] rounded-[150px] bg-gradient-to-b from-[#fb04f7] to-[#3c13f2]'>
        <div className='flex justify-center items-center w-[140px] h-[140px] rounded-[140px] bg-gradient[#555444] bg-[#000000]'>
<div className='flex justify-center items-center w-[130px] h-[130px] rounded-[130px] bg-gradient-to-r from-[#9404fb] to-[#692ff1]'>
    <div className='flex justify-center items-center w-[120px] h-[120px] rounded-[120px] bg-black'>
        <div className='flex animate-pulse absolute-//#endregion justify-center items-center w-[110px] h-[110px] rounded-[110px] bg-gradient-to-b from-[#9d03fd] to-[#693bc5]'>
            <div className='flex justify-center items-center w-[100px] h-[100px] rounded-[320px] bg-gradient[#555444] bg-black'>
                    <div><h2 className=' items-center w-full justify-between'><img src={Logo} alt='AIAD' /></h2></div>
            </div>
</div>
</div>
        </div>

    </div>

</div>

        </div>


        </div>

   

    
  )
}

export default Aiadlogomobile;