import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Greenpassaiadnftimage from '../assets/AIAD_Green_Pass_AIAD.png';
import Greenpassaiadnftimagebw from '../assets/AIAD_Green_Pass_BW.png';

// Button Component
function ActionButton({ label, link }) {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="px-4 py-2 bg-gradient-to-r from-[#00E6A0] to-[#03726b] text-black text-sm font-semibold rounded-lg shadow-md hover:bg-gradient-to-r hover:from-[#006D66] hover:to-[#00E6A0] hover:text-white transition-all duration-300"
    >
      {label}
    </a>
  );
}

// Card Component
function Card({ title, description, image, buttonLabel, buttonLink }) {
  return (
    <div className="flex justify-center items-center md:px-8 py-8">
      <div className="bg-gradient-to-br from-[#000000] via-[#0d0d0d] to-[#020202] border border-[#007f7f] backdrop-filter backdrop-blur-sm bg-opacity-90 rounded-3xl shadow-md p-6 w-[280px] md:w-[320px] hover:shadow-lg transition-shadow hover:scale-105 transform">
        <img
          className="rounded-2xl shadow-lg mb-6 hover:scale-105 transition-transform duration-300"
          src={image}
          alt={title}
        />
        <h3 className="text-lg font-semibold text-[#00E6A0] mb-3 text-center">
          {title}
        </h3>
        <p className="text-sm text-gray-300 mb-4 text-center">
          {description}
        </p>
        <ActionButton label={buttonLabel} link={buttonLink} />
      </div>
    </div>
  );
}

function Aiadgoldenpassnft() {
  const cardContents = [
    {
      title: 'Premium Access',
      description: 'Unlock next-gen tools to elevate your advertising campaigns.',
      image: Greenpassaiadnftimage,
      buttonLabel: 'Whitelist',
      buttonLink: 'https://docs.google.com/forms/d/e/1FAIpQLScnbB2xix5Feyd0Uit5cfC2EqzfhxuxI2HemUrOu_MD6yGujQ/viewform',
    },
    {
      title: 'Early Access Features',
      description: 'Be the first to experience our revolutionary platform updates.',
      image: Greenpassaiadnftimagebw,
      buttonLabel: 'Explore Now',
      buttonLink: 'https://docs.aiad.info/GreenPass.html',
    },
    {
      title: 'Community Perks',
      description: 'Join an exclusive circle of innovators in digital advertising.',
      image: Greenpassaiadnftimage,
      buttonLabel: 'Join Us',
      buttonLink: 'https://t.me/AIADOfficial',
    },
    {
      title: 'Sustainability',
      description: 'Contribute to a greener, more efficient ad ecosystem.',
      image: Greenpassaiadnftimagebw,
      buttonLabel: 'Get Started',
      buttonLink: 'https://docs.google.com/forms/d/e/1FAIpQLScnbB2xix5Feyd0Uit5cfC2EqzfhxuxI2HemUrOu_MD6yGujQ/viewform',
    },
    {
      title: 'Priority Support',
      description: 'Get direct access to premium customer and technical support.',
      image: Greenpassaiadnftimage,
      buttonLabel: 'Contact Us',
      buttonLink: 'https://docs.google.com/forms/d/e/1FAIpQLScnbB2xix5Feyd0Uit5cfC2EqzfhxuxI2HemUrOu_MD6yGujQ/viewform',
    },
    {
      title: 'Exclusive Events',
      description: 'Access private webinars and AIAD-hosted innovation talks.',
      image: Greenpassaiadnftimagebw,
      buttonLabel: 'Discover More',
      buttonLink: 'https://docs.aiad.info/GreenPass.html',
    },
  ];

  return (
    <div className="screen bg-gradient-to-b from-[#000000] via-[#001215] to-[#000000] w-full justify-center items-center border-none pb-[100px] pt-48 px-[25px]">
      {/* Title Section */}
      <div className="text-center mb-2">
        <h1 className="text-4xl md:text-5xl font-bold text-[#00E6A0]">AIAD Green Pass</h1>
        <p className="mt-4 text-lg md:text-xl text-gray-300 max-w-4xl mx-auto">
          The AIAD Green Pass is your gateway to exclusive benefits, innovative tools, and community collaboration. Designed for the future of advertising.
        </p>
      </div>

      {/* Swiper Section */}
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={30}
        slidesPerView={1}
        breakpoints={{
          768: { slidesPerView: 3 },
        }}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true,
          el: '.swiper-pagination',
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        className="pb-10"
      >
        {cardContents.map((content, index) => (
          <SwiperSlide key={index}>
            <Card
              title={content.title}
              description={content.description}
              image={content.image}
              buttonLabel={content.buttonLabel}
              buttonLink={content.buttonLink}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Aiadgoldenpassnft;
