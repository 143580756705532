import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import WhatIsAiad from './components/Whatisaiad';
import Navbar from './components/Navbar';
import Newsletter from './components/Newsletter';
import Beta from './components/Beta';
import Documentation from './components/Documentation';
import Card2 from './components/Card2';
import Banner from './components/Banner';
import Slidercards from './components/Slidercards';
import Aiadgreenpassnft from './components/Aiadgreenpassnft';
import Aiadlogomobile from './components/Aiadlogomobile';
import Aiadbanner from './components/Aiadbanner';
import Sliderkingdesktop from './components/Sliderkingdesktop';
import FundingCard from './components/FundingCard';
import Casestudies from './components/Casestudies';
import CallToAction from './components/CallToAction';

function App() {
  return (
    <Router>
      <div>
        <Navbar />
         <Routes>
          <Route path="/Beta" element={<Beta />} /> {/* This will only show when the URL path is '/about' */}
          <Route path="/Documentation" element={<Documentation />} /> {/* This will only show when the URL path is '/Documentation' */}
          <Route path="/" element={<> {/* Wrap existing components in an empty fragment */}
            <Banner />
            <Aiadlogomobile />
            <WhatIsAiad />
            <Sliderkingdesktop />
            <Slidercards />
            <FundingCard />
            <Casestudies />
            <Aiadbanner />
            <Card2 />
            <CallToAction />
            <Aiadgreenpassnft />            
            <Newsletter />
         
            
            
          </>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
